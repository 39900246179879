@media only screen and (max-width: 767px) {
  .footer {
    align-items: stretch;
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 1fr;
    justify-content: space-between;
    justify-items: center;
    margin-top: 5rem;
    padding-top: 1.75rem;
    text-align: center;
    --link-color: var(--color-text-main);
  }
  .contact {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-area: 2 / 1 / 3 / 2;
    margin-top: 1.875rem;
    row-gap: 0;
    width: 100%;
  }
  .phone {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .secondary {
    color: var(--color-text-description) !important;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
  }
  .primary {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
  }
  .list {
    display: flex;
    list-style-type: none;
  }
  .list__social {
    column-gap: 0.625rem;
    margin-top: 1.875rem;
  }
  .list__services {
    flex-direction: column;
    row-gap: 1rem;
  }
  .list__links {
    flex-direction: column;
    margin-bottom: 1.5rem;
    row-gap: 0.6rem;
  }
  .nav {
    display: block;
    margin-top: 1.75rem;
  }
  .services {
    display: none;
    flex-direction: column;
    row-gap: 1.25rem;
    width: 50%;
  }
  .confi {
    margin-top: 2.875rem;
  }
  .about {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    width: 100%;
  }
  .about li {
    flex: 1 0 38%;
  }
  .rights {
    display: flex;
    flex-direction: column;
    grid-area: 1 / 1 / 2 / 2;
    position: relative;
  }
  .copyright {
    display: none;
    margin-left: auto;
    margin-top: auto;
  }
  .button {
    font-size: 0.938rem;
    height: 2.625rem;
    line-height: 1.063rem;
    margin-top: 1rem;
    width: 100%;
  }
  .mobile {
    display: block;
  }
  .copyright_mobile {
    display: block;
    grid-area: 4 / 1 / 5 / 2;
    margin-top: 0.3rem;
  }
  .container {
    align-items: center;
    flex-direction: column;
  }
  .logo {
    position: relative !important;
    height: 2.5rem !important;
  }
  .logo__link {
    height: 2.625rem;
  }
  .logo__link[data-active='false'] {
    pointer-events: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .footer {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    --link-color: var(--color-text-main);
  }
  .copyright_mobile {
    display: none;
  }
  .contact {
    display: flex;
    flex-direction: column;
    row-gap: 1.125rem;
  }
  .phone {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
  .secondary {
    --link-color: var(--color-text-description);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
  }
  .primary {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
  }
  .list {
    display: flex;
    list-style-type: none;
  }
  .list__social {
    column-gap: 0.625rem;
    margin-top: auto;
  }
  .list__services {
    flex-direction: column;
    row-gap: 1rem;
  }
  .list__links {
    flex-direction: column;
    row-gap: 0.75rem;
    margin-bottom: 3rem;
  }
  .nav {
    margin-top: -0.25rem;
    margin-left: -3rem;
    column-gap: 1.875rem;
    display: block;
    flex-wrap: wrap;
  }
  .services {
    display: none;
    flex-direction: column;
    row-gap: 1.25rem;
    width: 50%;
  }
  .confi {
    margin-top: 3rem;
  }
  .about {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    width: 100%;
  }
  .about li {
    flex: 1 0 38%;
  }
  .rights {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .copyright {
    margin-top: auto;
    margin-bottom: 0.25rem;
  }
  .button {
    height: 2.875rem;
    width: 12.625rem;
  }
  .mobile {
    display: none;
    display: block;
  }
  .logo {
    width: 10rem !important;
    height: 2.5rem !important;
    left: -2.5rem !important;
  }
  .container {
    align-items: center;
    flex-direction: column;
  }
  .logo__link {
    height: 2.625rem;
  }
  .logo__link[data-active='false'] {
    pointer-events: none;
  }
}
@media only screen and (min-width: 1440px) {
  .footer {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    --link-color: var(--color-text-main);
  }
  .contact {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
  }
  .phone {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
  .secondary {
    color: var(--color-text-description) !important;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
  }
  .primary {
    width: fit-content;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
  }
  .list {
    display: flex;
    list-style-type: none;
  }
  .list__social {
    column-gap: 0.625rem;
    margin-top: auto;
  }
  .list__services {
    margin-top: -0.25rem;
    flex-direction: column;
    row-gap: 0.5rem;
  }
  .list__links {
    margin-left: -0.25rem;
    margin-top: -0.25rem;
    flex-direction: column;
    row-gap: 0.8rem;
  }
  .nav {
    column-gap: 0rem;
    margin-left: 1.75rem;
    display: flex;
    flex-wrap: wrap;
  }
  .services {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    width: 50%;
  }
  .confi {
    margin-top: 3rem;
  }
  .about {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    width: 100%;
  }
  .about li {
    flex: 1 0 38%;
  }
  .rights {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .copyright {
    margin-left: auto;
    margin-top: auto;
  }
  .button {
    height: 2.875rem;
    width: 12.625rem;
  }
  .mobile {
    display: none;
  }

  .logo {
    width: 12rem !important;
    height: 3.2rem !important;
    left: -4.8rem !important;
  }
  .copyright_mobile {
    display: none;
  }

  .logo__link {
    height: 2.625rem;
  }
  .logo__link[data-active='false'] {
    pointer-events: none;
  }
}
